<div>
  <br />
  <div class="align-center" *ngIf="!generating">
    <h3>Beskriv din poster här</h3>
    <div class="input-wrapper">
      <textarea [(ngModel)]="prompt" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
    <div>
      <h3>Välj stil</h3>
      <section id="thumbnail-carousel" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide" *ngFor="let style of styles" [ngClass]="{'selected': selectedStyle == style}"
              (click)="selectStyle(style)">
              <img src="{{globals.baseUrl}}/public-app/assets/style-thumbnails/{{style}}.jpg" />
              <span>{{styleTexts[style]}}</span>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <br/>
    <fancy-button [disabled]="prompt.length == 0" (click)="generate()">Generera posters</fancy-button>
  </div>
  <div class="align-center" *ngIf="generating">
    <span class="loader-with-text">Skapar förslag..</span>
    <br />
    <div class="progressbar">
      <span class="progress" #progress></span>
    </div>
  </div>

</div>
