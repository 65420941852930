import { Component, ElementRef } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

@Component({
  selector: 'generate-poster',
  templateUrl: './generate-poster.component.html',
  styleUrls: ['./generate-poster.component.scss']
})
export class GeneratePosterComponent {
  variationData: any;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState) {
    posterGenerationState.load();
  }

  async ngOnInit() {

  }

  isImageSelected() {
    return typeof (this.posterGenerationState.state.selectedImageIndex) != 'undefined';
  }


  hasCredits() {
    return this.globals.customerAccount.credits >= 10;
  }


  generateVariation(data) {
    this.variationData = data;
  }


  getStep() {
    if (!this.posterGenerationState.state.generatedImages) {
      return 'PROMPT';
    }

    if (!this.isImageSelected()) {
      return 'SELECT_IMAGE';
    }

    if (!this.posterGenerationState.state.selectedSize) {
      return 'SIZE_SELECT';
    }

    if (this.posterGenerationState.state.selectedSize) {
      return 'POSTER_ALIGNMENT';
    }
    return null;
  }


  async imagesGenerated() {
    delete this.variationData;
    console.log('imagesGenerated');
  }
}
