<div>

  <div class="drag-area {{imageOrientation}}" #dragAreaElement>
    <img class="poster-image" #posterImageElement
      [src]="safeUrl" />
    <div class="poster-overlay">
      <div class="outside"></div>
      <div class="size-border" #sizeBorderElement
        [ngStyle]="{'aspect-ratio': availableSizes[size].width + ' / ' + availableSizes[size].height}">
      </div>
      <div class="outside"></div>
    </div>
  </div>
  <br />
  <br />

</div>
