import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { Gtag } from 'src/app/services/gtag';



@Component({
  selector: 'wallpaper-configurator-button',
  templateUrl: './wallpaper-configurator-button.component.html',
  styleUrls: ['./wallpaper-configurator-button.component.scss']
})
export class WallpaperConfiguratorButtonComponent implements OnInit {
  ceilingHeight = 2400;
  wallWidth = 3000;

  constructor(private api: Api, private globals: Globals, private sanitizer: DomSanitizer, private gtag: Gtag) {

  }

  async triggerWallpaperConfigurator() {
    (window as any).triggerWallpaperConfigurator({ wallWidth: this.wallWidth, ceilingHeight: this.ceilingHeight });

    this.gtag.event('configurator', 'opened');
  }

  isReady() {
    return (window as any).triggerWallpaperConfigurator;
  }

  async ngOnInit() {

  }
}
