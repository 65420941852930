import { Injectable } from '@angular/core';



@Injectable()
export class Gtag {
  constructor() {

  }
  async gtag(action, name, data) {
    let win = window as any;
    if (win.gtag)
      win.gtag(action, name, data);
    else
      console.log(`No gtag`);
  }
  event(category: string, name: string) {
    this.gtag('event', name, {
      event_category: category
    });
  }
}
