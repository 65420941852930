import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Scroll } from 'src/app/services/scroll';

@Component({
  selector: 'ai-poster-configurator',
  templateUrl: './ai-poster-configurator.component.html',
  styleUrls: ['./ai-poster-configurator.component.scss']
})
export class AIPosterConfiguratorComponent {

  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, private route: ActivatedRoute, private scroll: Scroll, public posterGenerationState: PosterGenerationState) {

  }

  async ngOnInit() {
    try {
      this.globals.storeId = this.elementRef.nativeElement.getAttribute('store-id');
      console.log(this.globals.storeId)

      this.globals.customerAccount = await this.api.store().get<any>(`/account/current`);

      if (this.globals.customerAccount) {
        this.router.navigateByUrl('/generate-poster');
      }
      else {
        this.router.navigateByUrl('/login');
      }

    }
    catch (e) {
      this.router.navigateByUrl('/login');
    }

  }

  ngAfterContentInit() {
    this.scroll.setTopElement(this.elementRef.nativeElement);
  }
}


