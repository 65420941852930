<div class="align-center">
  <img class="ai-brain" src="{{globals.baseUrl}}/public-app/assets/ai-brain.png" />
  <br />
  <h2>Varför behöver jag köpa krediter?</h2>
  <br />
  <h4>
    Hjärnan är det organ som hos människan gör av med mest energi.
    <br />
    20% av all energi konsumeras av hjärnan.
  </h4>
  <h4>
    En AI är en digital representation av en mänsklig hjärna där de biologiska neuronerna ersatts med digitala
    matematiska beräkningar.
  </h4>
  <h4>
    En AI behöver således en hel del energi i form av elektricitet, lagringsutrymme, och processorkraft för att fungera.
    <br />
    Det är därför vi behöver ta betalt för att generera förslag.
  </h4>
  <br />
  <fancy-button (click)="close()">Köp krediter</fancy-button>
  <br />
  <br />
</div>
