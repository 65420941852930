<div class="ace">
  <div class="menu" #menu *ngIf="globals.customerAccount">
    <div class="menu-item clickable" [routerLink]="['/generate-poster']">
      <span>
        Skapa din AI-poster
      </span>
    </div>
    <div class="menu-item clickable" [routerLink]="['/add-credits']">
      <span>
        Fyll på krediter
      </span>
    </div>
    <div class="menu-item clickable" [routerLink]="['/history']">
      <span>
        Historik
      </span>
    </div>

    <div class="menu-item">
      <span>
        Du har {{globals.customerAccount.credits}} krediter
      </span>
    </div>
  </div>
  <br />
  <router-outlet></router-outlet>
  <br />
  <br />
  <latest-generated-images *ngIf="!posterGenerationState.state || !posterGenerationState.state.generatedImages"></latest-generated-images>
  <br />
  <br />
</div>
