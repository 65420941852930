import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'bildbyran-terms-dialog',
  templateUrl: './bildbyran-terms-dialog.component.html',
  styleUrls: ['./bildbyran-terms-dialog.component.scss']
})
export class BildbyranTermsDialogComponent {
  constructor(public dialogRef: MatDialogRef<BildbyranTermsDialogComponent>) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
