import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';



@Component({
  selector: 'klarna-checkout',
  templateUrl: './klarna-checkout.component.html',
  styleUrls: ['./klarna-checkout.component.scss']
})
export class KlarnaCheckoutComponent implements OnInit {
  klarnaJsUrl = 'https://x.klarnacdn.net/instantshopping/lib/v1/lib.js';
  window: any = window;
  klarna: any;

  @Input()
  initializeUrl: string;

  @Input()
  useAccountUrl: boolean = false;

  checkoutUrl: SafeResourceUrl;
  loading: boolean;
  klarnaLoaded: boolean;
  opacity = 0;

  @Output()
  paymentComplete = new EventEmitter();

  @Output()
  isPaid = new EventEmitter();

  @ViewChild('klarnaIframe', { static: false })
  klarnaIframe: ElementRef<HTMLIFrameElement>;
  iframeHeight: string;
  constructor(private api: Api, private globals: Globals, private sanitizer: DomSanitizer) {

  }

  async initKlarnaJsLib() {
    if (!this.window.klarnaAsyncCallback) {
      let script = document.createElement('script')
      script.setAttribute("type", "text/javascript");
      script.setAttribute("async", "");
      script.setAttribute("src", this.klarnaJsUrl);
      document.getElementsByTagName("head")[0].appendChild(script);
      await new Promise((resolve, reject) => {
        this.window.klarnaAsyncCallback = () => {
          console.log('Klarna loaded.');
          resolve(null);
        };
      });
    }
  }

  async initKlarnaCheckout() {

    let result;
    if (this.useAccountUrl)
      result = await this.api.account().post<any>(this.initializeUrl, {});
    else
      result = await this.api.store().post<any>(this.initializeUrl, {});

    if (result.succeeded) {
      this.checkoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.checkoutUrl)
    }

    window.addEventListener("message", e => {
      if (e.data) {
        console.log(`Received message: ${e.data.name}`);
        switch (e.data.name) {
          case 'klarnaPaymentComplete':
            this.isPaid.emit(true);
            break;
          case 'klarnaIframeSize':
            console.log(e.data.width);
            console.log(e.data.height);
            this.iframeHeight = `${e.data.height + 30}px`;
            break;
          case 'klarnaPaymentFailed':
            let checkoutUrl = this.checkoutUrl;
            this.checkoutUrl = null;
            setTimeout(() => {
              this.checkoutUrl = checkoutUrl;
            });
            break;
        }
      }
    });

  }

  async ngOnInit() {
    await this.initKlarnaCheckout();
  }
}
