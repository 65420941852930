import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

@Injectable()
export class Api {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private globals: Globals) {

  }

  private requester = (baseUrl: string, isAbsolute?: boolean) => {
    let http = this.http;
    return {
      get: function <T>(url: string) {
        return http.get(`${baseUrl}${url}`, {
          withCredentials: true,
          headers: {

          }
        }).toPromise() as Promise<T>
      },
      post: function <T>(url: string, data: any) {
        return http.post(`${baseUrl}${url}`, data, {
          withCredentials: true,
          headers: {

          }
        }).toPromise() as Promise<T>
      },
      put: function <T>(url: string, data: any) {
        return http.put(`${baseUrl}${url}`, data, {
          withCredentials: true,
          headers: {

          }
        }).toPromise() as Promise<T>
      },
      patch: function <T>(url: string, data: any) {
        return http.patch(`${baseUrl}${url}`, data, {
          withCredentials: true,
          headers: {

          }
        }).toPromise() as Promise<T>
      },
      delete: function <T>(url: string) {
        return http.delete(`${baseUrl}${url}`, {
          withCredentials: true,
          headers: {

          }
        }).toPromise() as Promise<T>
      }
    }
  }


  public storeUrl = (path:string = '') => {
    return `${this.baseUrl}/api/stores/public/${this.globals.storeId}${path}`;
  };

  public accountUrl = (path:string = '') => {
    return `${this.storeUrl(`/account/current${path}`)}`;
  };

  public store = () => {
    return this.requester(this.storeUrl());
  };

  public account = () => {
    return this.requester(this.accountUrl());
  };

}
