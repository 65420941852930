<div>
  <div>
    <h2 class="align-center">Vanlig poster eller canvas?</h2>
    <div class="row">
      <div class="col">
        <div class="poster-type clickable align-center"
          [ngClass]="{selected: posterGenerationState.state.type == 'POSTER'}" (click)="selectType('POSTER')">
          <img src="{{globals.baseUrl}}/public-app/assets/poster-mockup.png" />
          <h2>Poster</h2>
          <h3>{{getPosterTypePriceRange('POSTER')}}</h3>
        </div>
      </div>
      <div class="col">
        <div class="poster-type clickable align-center"
          [ngClass]="{selected: posterGenerationState.state.type == 'CANVAS'}" (click)="selectType('CANVAS')">
          <img src="{{globals.baseUrl}}/public-app/assets/canvas-mockup.png" />
          <h2>Canvas</h2>
          <h3>{{getPosterTypePriceRange('CANVAS')}}</h3>
        </div>
      </div>
    </div>
  </div>
  <div #sizeAnchor></div>
  <br />
  <br />
  <br />
  <br />
  <h2 class="align-center">Välj storlek</h2>
  <!--  <div class="available-sizes row">
    <div class="col-lg-6 col-md-12 size" *ngFor="let size of availableSizes | keyvalue">
      <div class="size-wrapper" (click)="selectSize(size.key)"
        [ngStyle]="{'aspect-ratio': size.value.width + ' / ' + size.value.height, 'transform': 'scale(' + sizeScales[size.key] + ')'}">
        <div class="size-name">{{size.key}}cm</div>
      </div>
    </div>
  </div> -->

  <div class="available-sizes" [ngClass]="{'size-selected': selectedSize}">
    <div class="size-wrapper" [ngClass]="{'selected': size.key == selectedSize}" (click)="selectSize(size.key)"
      *ngFor="let size of getAvailableSizes() | keyvalue"
      [ngStyle]="{'aspect-ratio': size.value.width + ' / ' + size.value.height, 'transform': 'scale(' + sizeScales[size.key] + ')'}">
      <div class="size-name">{{size.key}}cm
        <div class="price">
          {{getPosterTypeSizePrice(posterGenerationState.state.type, size.key)}}
        </div>
      </div>
    </div>
  </div>
  <div #availableFramesAnchor></div>
  <br />
  <br/>


  <div class="available-frames" *ngIf="posterGenerationState.state.type == 'POSTER' && selectedSize">
    <br/>
    <h2 class="align-center">Vill du ha en ram till postern?</h2>
    <div class="row">
      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('BLACK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-black.png" />
          <h2>Svart</h2>
          <h3>{{getFramePrice('BLACK')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('WHITE')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-white.png" />
          <h2>Vit</h2>
          <h3>{{getFramePrice('WHITE')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('OAK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-oak.png" />
          <h2>Ek</h2>
          <h3>{{getFramePrice('OAK')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_GOLD')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-gold.png" />
          <h2>Guld metall</h2>
          <h3>{{getFramePrice('M_GOLD')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_SILVER')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-silver.png" />
          <h2>Silver metall</h2>
          <h3>{{getFramePrice('M_SILVER')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_BLACK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-metal-black.png" />
          <h2>Svart metall</h2>
          <h3>{{getFramePrice('M_BLACK')}}</h3>
        </div>
      </div>
    </div>
    <br />
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-6 align-center">
      <fancy-button (click)="back()">Tillbaka till bilder</fancy-button>
    </div>
    <div class="col-xs-12 col-md-6 align-center" *ngIf="posterGenerationState.state.type == 'POSTER' && selectedSize">
      <fancy-button (click)="continue()">Fortsätt utan ram</fancy-button>
    </div>
  </div>

</div>
