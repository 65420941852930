import { Injectable, EventEmitter, Injector } from "@angular/core";
import { ICustomerAccount } from '../../../../../common/common-interfaces/customer-account';
import { IStoreSettings } from '../../../../../common/common-interfaces/store-settings';
import { environment } from '../../environments/environment';



@Injectable()
export class Globals {
  storeId?: string;
  customerAccount?: ICustomerAccount;
  baseUrl = environment.baseUrl;
  addingToCart: boolean = false;
  productInfo: {
    title: string;
  }
  storeSettings: IStoreSettings
  constructor(private injector: Injector) {

  }

  scrollToTop() {

  }


}
