import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterSize, horizontalCanvasSizes, verticalPosterSizes } from '../../../../../../../common/common-types/poster-size';
import { IPosterAlignment } from 'src/app/interfaces/poster-alignment';
import { PosterFrameMaterial } from '../../../../../../../common/common-types/poster-frame';
import { BildbyranTermsDialogComponent } from './bildbyran-terms-dialog/bildbyran-terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'bildbyran-home',
  templateUrl: './bildbyran-home.component.html',
  styleUrls: ['./bildbyran-home.component.scss']
})
export class BildbyranHomeComponent implements OnInit {
  paramsSubscription: Subscription = null;
  latestImages: string[] = []

  constructor(private api: Api, public globals: Globals, private sanitizer: DomSanitizer, private route: ActivatedRoute, private dialog: MatDialog) {

  }

  async ngOnInit() {
    this.latestImages = await this.api.store().get<any>(`/third-party/bildbyran/home-images`);
  }







  async showTerms() {
    let dialogRef = this.dialog.open(BildbyranTermsDialogComponent, {
      height: '400px',
      width: '600px',
      autoFocus: false
    });
  }


  async ngOnDestroy() {

  }
}
