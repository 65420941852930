import { size } from 'lodash';
import { v4 } from 'uuid';
import { PosterSize } from '../common-types/poster-size';
import { ReclaimBlame } from '../common-interfaces/poster-order';

const MM_PER_INCH = 25.4;

export const Guid = () => {
  return v4();
}

export const isLandscape = (size: PosterSize) => {
  let sizeSplit = size.split('x');
  return sizeSplit.length == 2 && parseInt(sizeSplit[0]) > parseInt(sizeSplit[1]);
}

export const isPortrait = (size: PosterSize) => {
  let sizeSplit = size.split('x');
  return sizeSplit.length == 2 && parseInt(sizeSplit[0]) < parseInt(sizeSplit[1]);
}

export const removePadding = (value: string) => {
  while (value && value.startsWith('0'))
    value = value.replace('0', '');
  return value;
}

export const pointsToMillimeters = (points: number, dpi: number) => {
  return (points / dpi) * MM_PER_INCH;
}

export const objectify = (error, level = 0) => {
  if (level < 5 && error && (typeof (error)).toLowerCase() == 'object') {
    var obj = {};
    Object.getOwnPropertyNames(error).forEach(function (key) {
      obj[key] = objectify(error[key], level + 1);
    });
    try {
      return JSON.stringify(obj);
    } catch (error) {
      return '<Too deep>';
    }
  } else {
    return error;
  }
};

export const stringify = message => {
  if (typeof (message) == 'string')
    return message;
  else if (message) {
    try {
      if (message instanceof Error)
        return `${message.message}\r\n${message.stack}`;
      return JSON.stringify(message);
    }
    catch (error) {
      return JSON.stringify(objectify(message));
    }
  }
  return null;
}

export const numberFormat = (value: number, keepDecimals?: boolean) => {
  let split = (value: string) => {
    let split = value.split(',');
    if (split.length == 3)
      return `${split[0]} ${split[1]}`;
    if (split.length == 4)
      return `${split[0]} ${split[1]} ${split[2]}`;
    if (split.length == 5)
      return `${split[0]} ${split[1]} ${split[2]} ${split[3]}`;
    else
      return split[0];
  }

  if (Number.isNaN(value))
    return '-';

  let formattedValue = value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    .replace(',', ' ')
    .replace(',', ' ')
    .replace(',', ' ')
    .replace(',', ' ')
    .replace(',', ' ')
    .replace('.', ',');

  if (!keepDecimals) {
    if (formattedValue.endsWith(',00'))
      formattedValue = formattedValue.split(',')[0];
  }

  return formattedValue;
  //return split(formattedValue);
}


export const partnerReclaimRefundAllowed = (reclaimBlame: ReclaimBlame) => {
  return ['ACE', 'DELIVERY', 'PRINT_PARTNER'].includes(reclaimBlame);
}
