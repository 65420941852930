export const IMAGE_GENERATION_STYLES = [
    'NONE',
    'PHOTO',
    'MATISSE',
    'OIL_PAINTING',
    'CHARCOAL_SKETCH',
    'CARTOON',
    'NOIR',
    'DRUNKEN_ARTIST',
    'DA_VINCI',
    'SURREALIST']

export type ImageGenerationStyle = typeof IMAGE_GENERATION_STYLES[number];

export interface IImageGenerationBatch {
    _id?: any,
    storeId: string,
    customerAccountId?: string,
    generationData?: {
        originalPrompt?: string,
        chatPrompt?: string,
        chatResponse?: string,
    },
    prompt: string,
    images: {
        originalUrl: string,
        upscaledUrl?: string,
        upscaleTaskId?: string,
        originalStorageInfo?: {
            fileName: string,
            url: string
        },
        upscaledStorageInfo?: {
            fileName: string,
            url: string
        },
        failedUpscales?: number
    }[],
    watermarkedImages?: {
        fileName: string,
        url: string,
    }[],
    variationInfo?: {
        batchId: string,
        imageIndex: number,
    },
    created: Date
}