<div class="ace bildbyran-checkout" *ngIf="globals.storeSettings">
  <div class="header">
    <div class="row">
      <div class="col">
        <img src="https://files.minposter.se/bb-logo.svg" style="width: 80px; height: 98px;">
      </div>
    </div>
  </div>
  <div class="content">
    <br />
    <h1 class="align-center">Våra populäraste bilder</h1>
    <div class="latest-images padded">
      <div class="row">
        <div class="col-md-3 col-sm-6" *ngFor="let imageId of latestImages">
          <a [routerLink]="['/third-party/bildbyran/checkout/image/' + imageId]">
            <img class="clickable"
              [src]="globals.baseUrl + '/api/stores/public/' + globals.storeId + '/third-party/bildbyran/image/' + imageId + '/thumb'" />
          </a>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-4">
        Min Poster AB
        <br />
        559352-4746
        <br />
        <a (click)="showTerms()" class="clickable" target="_blank">Köpvillkor</a>
      </div>

      <div class="col-4 align-center">
        <h4>Powered by</h4>
        <a href="https://aceprintpartner.com" target="_blank">
          <img src="https://files.minposter.se/ace-logo-white.png" class="ace-logo" alt="ACE Print Partner" />
        </a>
      </div>

      <div class="col-4 align-right">
        <a href="mailto:info@aceprintpartner.com">info&#64;aceprintpartner.com</a>
        <br />
        Sågareliden 7
        <br />
        241 96 Stockamöllan
      </div>

    </div>
  </div>
</div>
