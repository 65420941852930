import { Component, ElementRef, ViewChild } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import Interact from '@interactjs/interact'
import { DomSanitizer } from '@angular/platform-browser';
import { posterSizesByName } from '../../../../../../../common/common-types/poster-size';
import { WooCommerce } from 'src/app/services/woocommerce';
import { Scroll } from 'src/app/services/scroll';

@Component({
  selector: 'ai-poster-alignment-configurator',
  templateUrl: './poster-alignment-configurator.component.html',
  styleUrls: ['./poster-alignment-configurator.component.scss']
})
export class AiPosterAlignmentConfiguratorComponent {
  availableSizes = posterSizesByName;
  addingToCart?: boolean = false;

  @ViewChild('dragAreaElement') dragAreaElement?: ElementRef<HTMLDivElement>;
  @ViewChild('sizeBorderElement') sizeBorderElement?: ElementRef<HTMLDivElement>;
  @ViewChild('posterImageElement') posterImageElement?: ElementRef<HTMLImageElement>;


  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private sanitizer: DomSanitizer, private wooCommerce: WooCommerce, private scroll: Scroll
  ) {

  }

  async ngOnInit() {
    (this.posterGenerationState.state.generatedImages![this.posterGenerationState.state.selectedImageIndex!] as any).safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.posterGenerationState.state.generatedImages![this.posterGenerationState.state.selectedImageIndex!].url);

    setTimeout(() => {
      this.scroll.scrollToTop();
    }, 200)
  }

  saveAlignment() {

    this.posterGenerationState.save();
  }

  async back() {
    delete this.posterGenerationState.state.selectedSize;
    delete this.posterGenerationState.state.posterAlignment;
  }


  ngAfterContentInit() {
    let _this = this;

    setTimeout(() => {

      if (_this.posterGenerationState.state.posterAlignment) {
        console.log('sizeBorderElement')
        console.log(this.sizeBorderElement!.nativeElement.getBoundingClientRect().x);
        console.log('dragAreaElement')
        console.log(this.dragAreaElement!.nativeElement.getBoundingClientRect().x);
        console.log('sizeBorderElement width')
        console.log(this.sizeBorderElement!.nativeElement.getBoundingClientRect().width);
        let offsetX =
          (this.sizeBorderElement!.nativeElement.getBoundingClientRect().x
            - this.dragAreaElement!.nativeElement.getBoundingClientRect().x)
          + (this.sizeBorderElement!.nativeElement.getBoundingClientRect().width * _this.posterGenerationState.state.posterAlignment.xOffset);

        console.log('offsetX percent ', _this.posterGenerationState.state.posterAlignment.xOffset);
        console.log('offsetX ', offsetX);

        this.posterImageElement!.nativeElement.style.transform = `translate(${offsetX}px, 0px)`;
        this.posterImageElement!.nativeElement.setAttribute('data-x', `${offsetX}`);
        this.posterImageElement!.nativeElement.setAttribute('data-y', `0`);



      }
      else {
        let overflow = this.posterImageElement!.nativeElement.getBoundingClientRect().width - this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;
        let translateX =
          this.sizeBorderElement!.nativeElement.getBoundingClientRect().x
          - this.dragAreaElement!.nativeElement.getBoundingClientRect().x
          - (overflow / 2);
        this.posterImageElement!.nativeElement.style.transform = `translate(${translateX}px, 0px)`;
        this.posterImageElement!.nativeElement.setAttribute('data-x', `${translateX}`);
        this.posterImageElement!.nativeElement.setAttribute('data-y', `0`);

        console.log('translateX ', translateX);
        console.log('overflow ', overflow)
        console.log('width', _this.sizeBorderElement!.nativeElement.getBoundingClientRect().width)
        console.log('x', _this.posterImageElement!.nativeElement.getBoundingClientRect().x)
        console.log('xx', _this.dragAreaElement!.nativeElement.getBoundingClientRect().x)
        let xOffsetPercent = (((translateX - _this.posterImageElement!.nativeElement.getBoundingClientRect().x)
          + _this.dragAreaElement.nativeElement.getBoundingClientRect().x)
          - (overflow / 2)) / _this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;
        _this.posterGenerationState.state.posterAlignment = {
          xOffset: xOffsetPercent,
          yOffset: 0
        };
        _this.posterGenerationState.save();
      }



      let interact: any = Interact;

      // target elements with the "draggable" class
      interact('.drag-area .poster-image').draggable({
        // enable inertial throwing
        //   origin: `(${translateX}, 0)`,
        inertia: true,
        startAxis: 'x',
        lockAxis: 'x',
        // keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            //     restriction: 'parent',
            //   endOnly: true
          })
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
          // call this function on every dragmove event
          move: dragMoveListener,

          // call this function on every dragend event
          end(event: any) {
            console.log('end')
            console.log(event);


            console.log('Image position')
            console.log(_this.posterImageElement!.nativeElement.getBoundingClientRect());

            console.log('Border position')
            console.log(_this.sizeBorderElement!.nativeElement.getBoundingClientRect());

            let xOffset = _this.posterImageElement!.nativeElement.getBoundingClientRect().x - _this.sizeBorderElement!.nativeElement.getBoundingClientRect().x;

            console.log('xOffset')
            console.log(xOffset);

            let xOffsetPercent = xOffset / _this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;

            console.log('xOffsetPercent')
            console.log(xOffsetPercent);


            _this.posterGenerationState.state.posterAlignment = {
              xOffset: xOffsetPercent,
              yOffset: 0
            };
            _this.posterGenerationState.save();

            /*
            var textEl = event.target.querySelector('p')

            textEl && (textEl.textContent =
              'moved a distance of ' +
              (Math.sqrt(Math.pow(event.pageX - event.x0, 2) +
                Math.pow(event.pageY - event.y0, 2) | 0))
                .toFixed(2) + 'px')
                */
          }
        }
      })

      function dragMoveListener(event: any) {
        var target = event.target
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

        // translate the element
        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

        // update the posiion attributes
        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)
      }

      // this function is used later in the resizing and gesture demos
      (window as any).dragMoveListener = dragMoveListener;

    }, 300);
  }


  async addToCart() {
    this.addingToCart = true;
    let result = await this.api.store().post<any>(`/account/current/configured-posters`, {
      batchId: this.posterGenerationState.state.batchId,
      imageIndex: this.posterGenerationState.state.selectedImageIndex!,
      size: this.posterGenerationState.state.selectedSize!,
      type: this.posterGenerationState.state.type,
      posterAlignment: {
        xOffset: this.posterGenerationState.state.posterAlignment!.xOffset,
        yOffset: this.posterGenerationState.state.posterAlignment!.yOffset
      }
    });

    let type = this.posterGenerationState.state.type;
    let size = this.posterGenerationState.state.selectedSize;

    /*
    this.posterGenerationState.state = {
      type: 'poster'
    };
    this.posterGenerationState.save();
*/
    if (this.wooCommerce.isWooCommerce()) {
      let data = (window as any).productInfo;
      await this.wooCommerce.addToCart({
        configuredPosterId: result.configuredPosterId,
        type: type,
        size: size,
        frame: this.posterGenerationState.state.frame
          ? data.frame[size][this.posterGenerationState.state.frame].variant_id
          : undefined,
        quantity: 1
      });
    }

    this.addingToCart = false;
  }
}
