import { Component, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { IImageGenerationBatch } from '../../../../../../../common/common-interfaces/image-generation-batch';

@Component({
  selector: 'image-generation-history',
  templateUrl: './image-generation-history.component.html',
  styleUrls: ['./image-generation-history.component.scss']
})
export class ImageGenerationHistoryComponent {
  batches: IImageGenerationBatch[];
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private router: Router, private sanitizer: DomSanitizer) {

  }

  async ngOnInit() {
    this.batches = await this.api.account().get<any>(`/history/image-generation-batches/latest`);
  }


  async selectBatch(batch: IImageGenerationBatch) {
    this.posterGenerationState.reset();
    this.posterGenerationState.state.generatedImages = batch.images.map((image: any) => {
      return {
        url: image,
      };
    });
    this.posterGenerationState.state.batchId = batch._id;
    this.posterGenerationState.state.type = 'POSTER';
    await this.posterGenerationState.save();
    this.router.navigateByUrl('/generate-poster');
  }

}
