import { Injectable, EventEmitter, Injector } from "@angular/core";



@Injectable()
export class Scroll {
  topElement: HTMLElement;
  constructor(private injector: Injector) {

  }


  setTopElement(element: HTMLElement) {
    this.topElement = element;
  }

  scrollToTop() {
    this.topElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


}




