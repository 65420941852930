import { ApplicationRef, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCreditsComponent } from './components/ai-poster-configurator/add-credits/add-credits.component';
import { GeneratePosterComponent } from './components/ai-poster-configurator/generate/generate-poster.component';
import { ImageGenerationHistoryComponent } from './components/ai-poster-configurator/history/image-generation-history.component';
import { LoginComponent } from './components/login/login.component';
import { BildbyranCheckoutComponent } from './components/third-party/bildbyran/bildbyran-checkout.component';
import { BildbyranHomeComponent } from './components/third-party/bildbyran/bildbyran-home.component';

const routes: Routes = [
  {
    path: 'generate-poster', pathMatch: 'full', component: GeneratePosterComponent, children: []
  },
  {
    path: 'add-credits', pathMatch: 'full', component: AddCreditsComponent, children: []
  },
  {
    path: 'history', pathMatch: 'full', component: ImageGenerationHistoryComponent, children: []
  },
  {
    path: 'login', pathMatch: 'full', component: LoginComponent
  },
  {
    path: 'login', pathMatch: 'full', component: LoginComponent
  },
  {
    path: 'third-party/bildbyran/home', pathMatch: 'full', component: BildbyranHomeComponent
  },
  {
    path: 'third-party/bildbyran/checkout/image/:imageId', pathMatch: 'full', component: BildbyranCheckoutComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {

  constructor(appRef: ApplicationRef) {


  }



}
