import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Scroll } from 'src/app/services/scroll';
import { numberFormat } from '../../../../../../../common/common-helpers/utility';
import { PosterSize, canvasSizesByName, posterSizesByName, squareCanvasSizes, squarePosterSizes, verticalCanvasSizes, verticalPosterSizes } from '../../../../../../../common/common-types/poster-size';
import { PosterType } from '../../../../../../../common/common-types/poster-type';
import { PosterFrameMaterial } from '../../../../../../../common/common-types/poster-frame';
@Component({
  selector: 'poster-size-selector',
  templateUrl: './poster-size-selector.component.html',
  styleUrls: ['./poster-size-selector.component.scss']
})
export class PosterSizeSelectorComponent {
  @Input()
  availableSizes: PosterSize[];

  @Input()
  sizePrices: { [size: string]: number };

  @Input()
  framePrices: { [size: string]: { [color: string]: number } };

  @Input()
  hidePrices: boolean;

  selectedSize?: string;
  sizeScales = {
    '13x18': .18,
    '21x30': .21,
    '20x30': .20,
    '30x40': .3,
    '50x50': .5,
    '50x70': .5,
    '70x100': 1,
  }

  @ViewChild('availableFramesAnchor')
  availableFramesAnchor?: ElementRef<HTMLElement>;

  @Output()
  sizeSelected: EventEmitter<PosterSize> = new EventEmitter();

  @Output()
  frameSelected: EventEmitter<PosterFrameMaterial | 'none'> = new EventEmitter();

  availableSizesToDisplay: any[] = [];

  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private scroll: Scroll) {

  }

  async ngOnInit() {

  }

  ngOnChanges() {
    console.log('Changes');
    this.availableSizesToDisplay = this.getAvailableSizes();
  }

  getAvailableSizes() {
    let result = [];

    if (this.availableSizes)
      for (let size of this.availableSizes)
        if (posterSizesByName[size])
          result.push(Object.assign({ size: size }, posterSizesByName[size]));

    return result;
  }

  selectSize(size: string) {
    console.log('selectSize', size)
    this.selectedSize = size;
    setTimeout(() => {
      this.availableFramesAnchor.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);
    this.sizeSelected.emit(size as PosterSize);

    if (!this.frameExists(size)) {
      this.noFrame();
    }

  }

  getSizePrice(size: string) {
    let price = this.sizePrices[size];
    return `${numberFormat(price)} kr`;
  }

  getFramePrice(color: PosterFrameMaterial) {
    let price = this.framePrices[this.selectedSize][color];
    return `${numberFormat(price)} kr`;
  }

  frameExists(size: string) {
    return this.framePrices[size];
  }

  selectFrame(color: PosterFrameMaterial) {
    this.frameSelected.emit(color);
  }

  async noFrame() {
    console.log('noFrame')
    this.frameSelected.emit('none');
  }

  async continue() {

  }
}


