import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Scroll } from 'src/app/services/scroll';
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import Interact from '@interactjs/interact'
import { IWallpaperConfigurationData, BREADTH_WIDTH, WALLPAPER_BREADTH_BLEED_HORIZONTAL_MM, WALLPAPER_BREADTH_BLEED_VERTICAL_MM } from '../../../../../../common/common-interfaces/wallpaper-configuration-data';
import { Gtag } from 'src/app/services/gtag';

@Component({
  selector: 'wallpaper-configurator',
  templateUrl: './wallpaper-configurator.component.html',
  styleUrls: ['./wallpaper-configurator.component.scss']
})
export class WallpaperConfiguratorComponent {
  product: any;
  previewImageUrl: any;

  showConfigurator = false;
  aspectRatio: 0.9;
  showBreadths = true;

  @ViewChild('configuratorElement') configuratorElement?: ElementRef<HTMLDivElement>;
  @ViewChild('dragAreaElement') dragAreaElement?: ElementRef<HTMLDivElement>;
  @ViewChild('sizeBorderElement') sizeBorderElement?: ElementRef<HTMLDivElement>;
  @ViewChild('wallpaperImageElement') wallpaperImageElement?: ElementRef<HTMLImageElement>;

  configurationData: IWallpaperConfigurationData = {
    ceilingHeight: 2400,
    wallWidth: 3000,
    numberOfBreadths: 0,
    breadthAspectRatio: 1,
    wallAspectRatio: 1,
    offsetPercent: {
      x: 0,
      y: 0
    }
  };

  breadthLines: {
    xOffset: number,
    width: number
  }[] = [];

  errorMessage: string;
  initialized = false;
  imageLoaded = false;

  previewProduct: any;
  imagePreviewUrl: string;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, private route: ActivatedRoute, private scroll: Scroll, public posterGenerationState: PosterGenerationState, private http: HttpClient, private sanitizer: DomSanitizer, private zone: NgZone, private gtag: Gtag) {

  }

  async fetchProduct() {
    let productDataUrl = `${window.location.href.split('?')[0].split('#')[0]}.json`;
    if ((window as any).currentProduct) {
      productDataUrl = `/products/${(window as any).currentProduct.handle}.json`
    }

    this.product = (await this.http.get(productDataUrl, {
      withCredentials: true,
      headers: {

      }
    }).toPromise() as any).product;

    this.previewImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.images[0].src);

  }

  async ngOnInit() {
    try {
      await this.fetchProduct();
      /*
            if (this.product.images && this.product.images.length > 1) {
              this.previewImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.images[1].src);
            }
            else {
              this.previewImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.image.src);
            }
            */

      console.log(this.product);
    }
    catch (e) {

    }

    let _this = this;
    (window as any).triggerWallpaperConfigurator = (options: any) => {
      _this.zone.run(() => {
        _this.startConfiguration(options);
      });
    }


    (window as any).showImagePreview = (product: any) => {
      _this.zone.run(() => {
        _this.showImagePreview(product);
      });
    }

  }

  async addToCart() {
    let formData = {
      items: [{
        id: this.product.variants[0].id,
        quantity: this.getSquareMeters(),
        properties: {
          _wallpaperConfiguration: JSON.stringify(this.configurationData)
        }
      }]
    };
    let result = await this.http.post(`${(window as any).Shopify.routes.root}cart/add.js`, formData, {
      withCredentials: true,
      headers: {

      }
    }).toPromise();

    console.log(result);

    this.gtag.event('configurator', 'add_to_cart');

    window.location.href = `/cart`;
  }

  async cancel() {
    this.gtag.event('configurator', 'closed');
    this.showConfigurator = false;
  }

  getPrice() {
    return parseFloat(this.product.variants[0].price) * this.getSquareMeters();
  }

  getSquareMeters() {
    return Math.ceil((this.configurationData.wallWidth / 1000) * (this.configurationData.ceilingHeight / 1000));
  }

  async updateBreadths() {
    console.log('Updating breadths');
    this.configurationData.numberOfBreadths = Math.ceil(this.configurationData.wallWidth / BREADTH_WIDTH);
    this.configurationData.breadthAspectRatio = BREADTH_WIDTH / this.configurationData.ceilingHeight;
    this.configurationData.breadthAspectRatioWithBleed = (BREADTH_WIDTH + (WALLPAPER_BREADTH_BLEED_HORIZONTAL_MM * 2)) / (this.configurationData.ceilingHeight + (WALLPAPER_BREADTH_BLEED_VERTICAL_MM * 2));
    this.configurationData.wallAspectRatio = parseFloat((this.configurationData.wallWidth / this.configurationData.ceilingHeight).toFixed(2));

    let screenWidth = this.configuratorElement!.nativeElement.getBoundingClientRect().width  //  window.innerWidth;
    let screenHeight = this.configuratorElement!.nativeElement.getBoundingClientRect().height //window.innerHeight;

    //  let screenWidth = window.innerWidth;
    //  let screenHeight = window.innerHeight;

    let maxImageHeight = Math.min((screenWidth * 0.9) * (1 / this.configurationData.wallAspectRatio), (screenHeight * 0.6));
    let imageHeight = this.wallpaperImageElement.nativeElement.height;



    console.log(screenWidth, screenHeight, maxImageHeight, imageHeight);


    imageHeight = maxImageHeight;
    this.wallpaperImageElement.nativeElement.style.height = `${imageHeight}px`;

    let imageWidth = this.wallpaperImageElement.nativeElement.width;
    let imageAspectRatio = imageWidth / imageHeight;
    console.log(imageWidth, imageHeight, imageAspectRatio);

    this.breadthLines = [];
    let breadthPixelWidth = imageHeight * this.configurationData.breadthAspectRatio;
    for (let i = 0; i < this.configurationData.numberOfBreadths; i++) {
      this.breadthLines.push({
        xOffset: breadthPixelWidth * i,
        width: breadthPixelWidth
      });
    }

    console.log(this.configurationData);
    console.log(this.initialized);
    if (this.initialized) {
      if (this.configurationData.wallAspectRatio > imageAspectRatio) {
        this.errorMessage = "Din vägg är tyvärr för bred för denna bild. Vänligen välj en annan bild eller minska väggytan.";
      }
      else {
        this.errorMessage = null;
      }
    }
  }

  async onImageLoaded() {
    console.log('Image loaded');
    this.imageLoaded = true;
  }

  async startConfiguration(options?) {
    if (options) {
      if (options.ceilingHeight)
        this.configurationData.ceilingHeight = options.ceilingHeight;
      if (options.wallWIdth)
        this.configurationData.wallWidth = options.wallWidth;
    }

    if ((window as any).currentProduct) {
      await this.fetchProduct();
    }

    this.showConfigurator = true;
    this.initialized = false;
    this.imageLoaded = false;

    await new Promise(resolve => {
      let i = setInterval(() => {
        if (this.imageLoaded) {
          console.log('Image loadedd');
          clearInterval(i);
          resolve(null);
        }
      }, 100);
    })


    setTimeout(async () => {
      await this.updateBreadths();

      setTimeout(() => {
        let overflow = this.wallpaperImageElement!.nativeElement.getBoundingClientRect().width - this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;
        let translateX =
          this.sizeBorderElement!.nativeElement.getBoundingClientRect().x
          - this.dragAreaElement!.nativeElement.getBoundingClientRect().x
          - (overflow / 2);
        this.wallpaperImageElement!.nativeElement.style.transform = `translate(${translateX}px, 0px)`;
        this.wallpaperImageElement!.nativeElement.setAttribute('data-x', `${translateX}`);
        this.wallpaperImageElement!.nativeElement.setAttribute('data-y', `0`);

        console.log('translateX ', translateX);
        console.log('overflow ', overflow)
        console.log('width', this.sizeBorderElement!.nativeElement.getBoundingClientRect().width)
        console.log('x', this.wallpaperImageElement!.nativeElement.getBoundingClientRect().x)
        console.log('xx', this.dragAreaElement!.nativeElement.getBoundingClientRect().x)
        let xOffsetPercent = (((translateX - this.wallpaperImageElement!.nativeElement.getBoundingClientRect().x)
          + this.dragAreaElement.nativeElement.getBoundingClientRect().x)
          - (overflow / 2)) / this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;

        this.configurationData.offsetPercent.x = xOffsetPercent;
        let _this = this;


        let dragMoveListener = (event: any) => {
          console.log(event);
          var target = event.target
          // keep the dragged position in the data-x/data-y attributes
          var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
          var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

          let sizeBorderLeft = (_this.sizeBorderElement!.nativeElement.getBoundingClientRect().x - _this.configuratorElement!.nativeElement.getBoundingClientRect().x);


          console.log(sizeBorderLeft);
          console.log(x);
          if (x > sizeBorderLeft)
            x = sizeBorderLeft;

          let xMinimum = sizeBorderLeft
            + (_this.sizeBorderElement!.nativeElement.getBoundingClientRect().width - _this.wallpaperImageElement!.nativeElement.getBoundingClientRect().width);
          console.log(_this.sizeBorderElement!.nativeElement.getBoundingClientRect().width);
          console.log(_this.wallpaperImageElement!.nativeElement.getBoundingClientRect().width);
          console.log(xMinimum);

          if (x < xMinimum)
            x = xMinimum;

          // translate the element
          target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

          // update the posiion attributes
          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
        }

        setTimeout(() => {

          let interact: any = Interact;

          console.log(interact('.drag-area .wallpaper-image'));
          console.log(interact(_this.wallpaperImageElement.nativeElement));
          console.log(interact(_this.wallpaperImageElement.nativeElement).draggable);

          let i = interact(_this.wallpaperImageElement.nativeElement);
          // target elements with the "draggable" class
          if (i.draggable) {
            i.draggable({
              // enable inertial throwing
              //   origin: `(${translateX}, 0)`,
              inertia: true,
              startAxis: 'x',
              lockAxis: 'x',
              // keep the element within the area of it's parent
              modifiers: [
                interact.modifiers.restrictRect({
                  //     restriction: 'parent',
                  //   endOnly: true
                })
              ],
              // enable autoScroll
              autoScroll: true,

              listeners: {
                // call this function on every dragmove event
                move: dragMoveListener,

                // call this function on every dragend event
                end: (event: any) => {
                  console.log('end')
                  console.log(event);


                  console.log('Image position')
                  console.log(_this.wallpaperImageElement!.nativeElement.getBoundingClientRect());

                  console.log('Border position')
                  console.log(_this.sizeBorderElement!.nativeElement.getBoundingClientRect());

                  let xOffset = _this.wallpaperImageElement!.nativeElement.getBoundingClientRect().x - _this.sizeBorderElement!.nativeElement.getBoundingClientRect().x;

                  console.log('xOffset')
                  console.log(xOffset);

                  //  let xOffsetPercent = xOffset / _this.sizeBorderElement!.nativeElement.getBoundingClientRect().width;
                  let xOffsetPercent = xOffset / _this.wallpaperImageElement!.nativeElement.getBoundingClientRect().width;
                  console.log('xOffsetPercent')
                  console.log(xOffsetPercent);
                  _this.configurationData.offsetPercent.x = xOffsetPercent;

                  /*
                  var textEl = event.target.querySelector('p')

                  textEl && (textEl.textContent =
                    'moved a distance of ' +
                    (Math.sqrt(Math.pow(event.pageX - event.x0, 2) +
                      Math.pow(event.pageY - event.y0, 2) | 0))
                      .toFixed(2) + 'px')
                      */
                }
              }
            });



            // this function is used later in the resizing and gesture demos
            (window as any).dragMoveListener = dragMoveListener;

            _this.initialized = true;
            _this.updateBreadths();
          }
        }, 300);
      }, 100);

    }, 10);

  }


  async showImagePreview(product: any) {
    this.previewProduct = product;
    if (product.mockupImageUrl)
      this.imagePreviewUrl = product.mockupImageUrl;
    else
      this.imagePreviewUrl = product.imageUrl;
  }

  async hideImagePreview() {
    delete this.imagePreviewUrl;
    delete this.previewProduct;
  }

  ngAfterContentInit() {

  }
}


