<div>
  <h3 class="align-center">Här är våra förslag!</h3>
  <h4 class="align-center">Välj den bild du gillar bäst eller försök igen</h4>
  <div class="suggested-images row">
    <div class="col-6" *ngFor="let image of posterGenerationState.state.generatedImages; let i = index">
      <div class="suggested-image">
        <img [src]="image.safeUrl" (click)="selectImage(i)" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col align-center">
      <fancy-button (click)="back()">Försök igen</fancy-button>
    </div>
  </div>

</div>
