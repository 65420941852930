import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Splide } from '@splidejs/splide';
import { ImageGenerationStyle, IMAGE_GENERATION_STYLES } from '../../../../../../../common/common-interfaces/image-generation-batch';

@Component({
  selector: 'text-prompt-image-generator',
  templateUrl: './text-prompt-image-generator.component.html',
  styleUrls: ['./text-prompt-image-generator.component.scss']
})
export class TextPromptImageGeneratorComponent {
  prompt: string = '';
  generating: boolean = false;


  progressIntervalHandle: any;

  @Input()
  variationData: { batchId: string, imageIndex: string }

  @Output()
  imagesGenerated = new EventEmitter();



  @ViewChild('progress')
  progress: ElementRef<HTMLElement>;

  selectedStyle: ImageGenerationStyle = 'NONE';
  styles = IMAGE_GENERATION_STYLES;

  styleTexts = {
    'NONE': 'Ingen',
    'PHOTO': 'Foto',
    'MATISSE': 'Matisse',
    'OIL_PAINTING': 'Oljemålning',
    'CHARCOAL_SKETCH': 'Kolskiss',
    'CARTOON': 'Tecknad',
    'NOIR': "Noir",
    'DRUNKEN_ARTIST': "Berusad konstnär",
    'DA_VINCI': "Da Vinci",
    'SURREALIST': "Surrealism",
  }

  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private posterGenerationState: PosterGenerationState) {

  }

  async ngOnInit() {
    if (this.variationData) {
      this.generateVariation(this.variationData.batchId, this.variationData.imageIndex);
    }
  }

  ngAfterViewInit() {
    if (!this.variationData) {
      new Splide('#thumbnail-carousel', {
        fixedWidth: 150,
        gap: 10,
        rewind: true,
        pagination: false,
      }).mount();
    }
  }

  selectStyle(style: ImageGenerationStyle) {
    this.selectedStyle = style;
  }


  async generate() {
    this.generating = true;

    let progress = 0;
    this.progressIntervalHandle = setInterval(() => {
      progress += 1;
      if (progress > 90) {
        clearInterval(this.progressIntervalHandle);
      }
      this.progress.nativeElement.style.width = `${progress}%`;
    }, 300);

    try {
      let result = await this.api.account().post<any>(`/generate/stylized-from-prompt`, {
        prompt: this.prompt,
        style: this.selectedStyle
      });
      this.posterGenerationState.state.generatedImages = result.batch.images.map((image: any) => {
        return {
          url: image,
        };
      });
      this.posterGenerationState.state.batchId = result.batch._id;
      this.posterGenerationState.state.type = 'POSTER';

      this.globals.customerAccount!.credits = result.remainingCredits;
      this.posterGenerationState.save();
    } catch (e) {

    }
    this.generating = false;
    this.imagesGenerated.emit();
  }

  async generateVariation(batchId: string, imageIndex: string) {
    this.generating = true;

    let progress = 0;
    this.progressIntervalHandle = setInterval(() => {
      progress += 1;
      if (progress > 90) {
        clearInterval(this.progressIntervalHandle);
      }
      this.progress.nativeElement.style.width = `${progress}%`;
    }, 300);

    try {
      let result = await this.api.account().post<any>(`/generate/variation`, {
        batchId: batchId,
        imageIndex: imageIndex
      });
      this.posterGenerationState.state.generatedImages = result.batch.images.map((image: any) => {
        return {
          url: image,
        };
      });
      this.posterGenerationState.state.batchId = result.batch._id;
      this.posterGenerationState.state.type = 'POSTER';

      this.globals.customerAccount!.credits = result.remainingCredits;
      this.posterGenerationState.save();
    } catch (e) {
      console.log('error')
      console.log(e)
    }
    this.generating = false;
    this.imagesGenerated.emit();
  }

}
