import { Injectable, EventEmitter, Injector } from "@angular/core";
import { PosterFrameMaterial } from "../../../../../common/common-types/poster-frame";
import { PosterType } from '../../../../../common/common-types/poster-type'

export interface IPosterGenerationState {
  batchId?: string,
  type?: PosterType,
  generatedImages?: {
    safeUrl?: any,
    url: string,
  }[],
  frame?: PosterFrameMaterial,
  selectedImageIndex?: number,
  selectedSize?: string,
  posterAlignment?: {
    xOffset: number,
    yOffset: number
  }
}



@Injectable()
export class PosterGenerationState {
  state: IPosterGenerationState = {};
  constructor(private injector: Injector) {

  }

  async reset() {
    this.state = {};
    await this.save();
  }

  async save() {
    window.localStorage.setItem('poster-generation-state', JSON.stringify(this.state));
  }

  async load() {
    let state = window.localStorage.getItem('poster-generation-state');
    if (state) {
      this.state = JSON.parse(state);
    }
  }

}
