import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';



@Component({
  selector: 'public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(private api: Api, private globals: Globals, private sanitizer: DomSanitizer, private elementRef: ElementRef) {
    this.globals.storeId = this.elementRef.nativeElement.getAttribute('storeId');


  }



  async ngOnInit() {
    if (this.globals.storeId) {
      this.globals.storeSettings = await this.api.store().get<any>(`/settings`);
    }
  }
}
