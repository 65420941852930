<div *ngIf="!waitingForCode">
  <form class="align-center">
    <h4>Ange din e-postadress</h4>
    <div class="input-wrapper">
      <input class="styled" type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" />
    </div>
    <fancy-button (click)="sendCode()">Skicka inloggningskod</fancy-button>
  </form>
</div>

<div *ngIf="waitingForCode">
  <div class="align-center">
    <h4>Ange inloggningskoden som skickats till din e-postadress</h4>
    <div class="input-wrapper">
      <input class="styled align-center" #codeInput type="text" [(ngModel)]="code" [ngModelOptions]="{standalone: true}" />
    </div>
    <button class="styled" (click)="verifyCode()">Ange</button>
    <div *ngIf="loginFailed">
      <h5>Inloggningen misslyckades</h5>
      <fancy-button (click)="reset()">Kontrollera e-postadressen</fancy-button>
    </div>
  </div>
</div>
