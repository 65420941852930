import { Component, ElementRef, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'fancy-button',
  templateUrl: './fancy-button.component.html',
  styleUrls: ['./fancy-button.component.scss']
})
export class FancyButtonComponent {
  @Input()
  disabled: boolean = false;

  @Output()
  click: EventEmitter<any> = new EventEmitter();

  content: SafeHtml = '';

  @ViewChild('actualContent', { static: true }) actualContent: ElementRef;

  constructor(private sanitizer: DomSanitizer) {

  }

  buttonClicked() {
    this.click.emit();
  }

  ngAfterViewInit() {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.actualContent.nativeElement.innerHTML.split(' ').join('&nbsp;'));
  }

}
