import { Component, ElementRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

@Component({
  selector: 'payment-finished',
  templateUrl: './payment-finished.component.html',
  styleUrls: ['./payment-finished.component.scss']
})
export class PaymentFinishedComponent {

  selectedCredits = 0;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, public posterGenerationState: PosterGenerationState, private bottomSheetRef: MatBottomSheetRef<PaymentFinishedComponent>) {

  }

  async ngOnInit() {

  }

  async continue() {
    this.router.navigate(['/generate-poster']);
    this.close();
  }

  async close() {
    this.bottomSheetRef.dismiss();
  }

}
