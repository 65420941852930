<ol class="steps">
  <li [ngClass]="{'current': getStep() == 'PROMPT'}">Beskriv poster</li>
  <li [ngClass]="{'current': getStep() == 'SELECT_IMAGE'}">Välj bild</li>
  <li [ngClass]="{'current': getStep() == 'SIZE_SELECT'}">Välj typ & storlek</li>
  <li [ngClass]="{'current': getStep() == 'POSTER_ALIGNMENT'}">Positionera bild</li>
  <li >Beställ poster</li>
</ol>

<div *ngIf="!posterGenerationState.state.generatedImages && !globals.addingToCart">
  <div *ngIf="hasCredits()">
    <text-prompt-image-generator (imagesGenerated)="imagesGenerated()" [variationData]="variationData">
    </text-prompt-image-generator>
  </div>
  <div *ngIf="!hasCredits()">
    <div class="align-center">
      <h1>Attans!</h1>
      <h3>Du har inga krediter kvar.</h3>
      <br />
      <fancy-button [routerLink]="['/add-credits']">Fyll på krediter</fancy-button>
    </div>
  </div>
</div>

<div *ngIf="posterGenerationState.state.generatedImages || globals.addingToCart">
  <image-batch-suggestions *ngIf="!isImageSelected()" (generateVariation)="generateVariation($event)">
  </image-batch-suggestions>

  <div *ngIf="isImageSelected()">
    <ai-poster-size-selector *ngIf="!posterGenerationState.state.selectedSize && !globals.addingToCart"></ai-poster-size-selector>

    <div *ngIf="posterGenerationState.state.selectedSize || globals.addingToCart">
      <ai-poster-alignment-configurator>
      </ai-poster-alignment-configurator>
      <!--
      <div *ngIf="this.posterGenerationState.state.posterAlignment">
        <finish-configuration></finish-configuration>
      </div>
    -->
    </div>
  </div>
</div>
