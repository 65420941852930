import { Component, ElementRef } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

@Component({
  selector: 'finish-configuration',
  templateUrl: './finish-configuration.component.html',
  styleUrls: ['./finish-configuration.component.scss']
})
export class FinishConfigurationComponent {
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState) {

  }

  async ngOnInit() {

  }


  async back() {
    delete this.posterGenerationState.state.posterAlignment;
  }

}
