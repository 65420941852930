<div>
  <br />
  <br />
  <div *ngIf="!selectedCredits">
    <div class="row">
      <div class="col-12 align-center">
        <h2>Köp krediter för att fortsätta att generera förslag</h2>
        <h3 (click)="openCreditInfo()" class="open-credit-info"><a>Varför behöver jag köpa krediter?</a></h3>
        <br/>
      </div>

      <div class="col-lg-4 align-center">
        <div class="credit-option" (click)="selectCredits(100)">
          <div class="button-flipper">
            <div class="front-button">
              <h3>100 krediter</h3>
              <h5>19 kr</h5>
            </div>
            <div class="back-button">
              <h2>Köp nu</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 align-center">
        <div class="credit-option" (click)="selectCredits(200)">
          <div class="button-flipper">
            <div class="front-button">
              <h3>200 krediter</h3>
              <h5>29 kr</h5>
            </div>
            <div class="back-button">
              <h2>Köp nu</h2>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-4 align-center">
        <div class="credit-option" (click)="selectCredits(500)">
          <div class="button-flipper">
            <div class="front-button">
              <h3>500 krediter</h3>
              <h5>59 kr</h5>
            </div>
            <div class="back-button">
              <h2>Köp nu</h2>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col align-center">
        <h4>Varje förslag(4 bilder) kostar 10 krediter.</h4>
      </div>
    </div>
  </div>


  <klarna-checkout *ngIf="selectedCredits && !paymentFinished" [useAccountUrl]="true"
    [initializeUrl]="'/third-party/klarna/initialize-credit-purchase?credits=' + selectedCredits" (isPaid)="isPaid()">
  </klarna-checkout>
</div>
