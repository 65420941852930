<div class="align-center">
  <h2>Senaste AI-bilderna från våra användare</h2>
  <div class="row">
    <div class="col-sm-6 col-md-3 lg-2" *ngFor="let url of imageUrls">
      <div class="image">
        <img [src]="url" />
      </div>
    </div>
  </div>



</div>
