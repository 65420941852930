import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { SelectedImageComponent } from '../bottom-sheets/selected-image/selected-image.component';

@Component({
  selector: 'image-batch-suggestions',
  templateUrl: './image-batch-suggestions.component.html',
  styleUrls: ['./image-batch-suggestions.component.scss']
})
export class ImageBatchSuggestionsComponent {
  @Output()
  generateVariation: EventEmitter<{ batchId: string, imageIndex: number }> = new EventEmitter<{ batchId: string, imageIndex: number }>();
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private sanitizer: DomSanitizer, private bottomSheet: MatBottomSheet) {

  }

  async ngOnInit() {
    for (let image of this.posterGenerationState.state.generatedImages!) {
      (image as any).safeUrl = this.sanitizer.bypassSecurityTrustUrl(image.url);
    }
  }

  selectImage(index: any) {
    let sheet = this.bottomSheet.open(SelectedImageComponent, {
      data: {
        imageUrl: this.posterGenerationState.state.generatedImages![index].url,
        selectImage: () => {
          this.posterGenerationState.state.selectedImageIndex = index;
          this.posterGenerationState.save();
        },
        createVariation: async () => {
          let data = { batchId: this.posterGenerationState.state.batchId!, imageIndex: index };
          await this.posterGenerationState.reset();
          this.generateVariation.emit(data);
        }
      }
    });
  }





  async back() {
    await this.posterGenerationState.reset();
  }
}
