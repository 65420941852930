<div class="ace bildbyran-checkout" *ngIf="globals.storeSettings">
  <div class="header">
    <div class="row">
      <div class="col">
        <img class="clickable" [routerLink]="['/third-party/bildbyran/home']"
          src="https://files.minposter.se/bb-logo.svg" style="width: 80px; height: 98px;">
      </div>
    </div>
  </div>
  <div class="content">

    <div *ngIf="!orderId">

      <div *ngIf="!selectedSize || !selectedFrame">
        <br />
        <div class="align-center" *ngIf="imageUrl">
          <img [src]="imageUrl" class="preview-image" />
        </div>
        <br />
        <poster-size-selector [availableSizes]="availableSizes" [hidePrices]="true" [sizePrices]="sizePrices" [framePrices]="framePrices"
          (sizeSelected)="sizeSelected($event)" (frameSelected)="frameSelected($event)"></poster-size-selector>
      </div>




      <div *ngIf="imageUrl && selectedSize && selectedFrame && imageOrientation == 'landscape'">
        <div class="row">
          <div class="col">
            <br />
            <h2 class="align-center">Placera bilden där du vill ha den</h2>
            <poster-alignment-configurator [imageUrl]="imageUrl" [size]="selectedSize"
              [posterAlignment]="posterAlignment"></poster-alignment-configurator>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h3 class="align-center">Pris: {{getPrice()}} kr</h3>
          </div>
        </div>

        <div class="row" *ngIf="!initializingPayment">
          <div class="col-lg-6 align-center">
            <div class="padded">
              <button class="styled fullwidth" (click)="backToSizes()">Välj en annan storlek</button>
            </div>
          </div>
          <div class="col-lg-6 align-center ">
            <div class="padded">
              <button class="styled fullwidth" (click)="proceedToPayment()">Till betalning</button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="orderId">
      <ng-container *ngIf="!paymentFinished">
        <div class="align-center">
          <div class="align-center">
            <img class="clickable" src="https://files.minposter.se/bb-info.png" style="width: 80px;">
            <div class="purchase-info">
              BESTÄLL IDAG • LEVERANS MED POSTNORD 2-4 ARBETSDAGAR •
              TILLVERKNING I SVERIGE • INGEN ÅNGERRÄTT
              SÄKRA BETALNINGAR • FRÅGOR? <a href="mailto:bb@aceprintpartner.com">KLICKA HÄR</a>
            </div>
          </div>

          <klarna-checkout [initializeUrl]="'/third-party/klarna/poster-order/' + orderId + '/initialize'"
            (isPaid)="isPaid()">
          </klarna-checkout>
        </div>
      </ng-container>


      <div *ngIf="paymentFinished">
        <br />
        <br />
        <h1 class="align-center">Tack för din beställning!</h1>
        <h2 class="align-center">Vi skickar ett bekräftelsemail inom kort.</h2>
        <h3 class="align-center">Din orderreferens: {{orderId}}</h3>
      </div>
    </div>

  </div>
  <div class="footer">
    <div class="row">
      <div class="col-4">
        Min Poster AB
        <br />
        559352-4746
        <br />
        <a (click)="showTerms()" class="clickable" target="_blank">Köpvillkor</a>
      </div>

      <div class="col-4 align-center">
        <h4>Powered by</h4>
        <a href="https://aceprintpartner.com" target="_blank">
          <img src="https://files.minposter.se/ace-logo-white.png" class="ace-logo" alt="ACE Print Partner" />
        </a>
      </div>

      <div class="col-4 align-right">
        <a href="mailto:info@aceprintpartner.com">info&#64;aceprintpartner.com</a>
        <br />
        Sågareliden 7
        <br />
        241 96 Stockamöllan
      </div>

    </div>
  </div>
</div>
