import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter, Injector } from "@angular/core";
import { Globals } from "./globals";
import { PosterGenerationState } from "./poster-generation-state";




@Injectable()
export class WooCommerce {
  constructor(private injector: Injector, private http: HttpClient, private posterGenerationState: PosterGenerationState, private globals: Globals) {

  }


  isWooCommerce() {
    return (window as any).woocommerce_params;
  }

  async addToCart(data: any) {
    console.log('addToCart', data);

    let result : any = await this.http.post(`${(window as any).ajaxurl}?action=ace_wc_add_to_cart`, Object.assign({ action: 'ace_wc_add_to_cart' }, data), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).toPromise();
    console.log('addToCart result', result);

    if(result.succeeded){
      this.globals.addingToCart = true;
      await this.posterGenerationState.reset();
      window.location.replace((window as any).carturl);
    }
  }




}
