import { Component, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Api } from "src/app/services/api";
import { Globals } from "src/app/services/globals";
import { Scroll } from "src/app/services/scroll";

@Component({
  selector: 'wallpaper-finder',
  templateUrl: './wallpaper-finder.component.html',
  styleUrls: ['./wallpaper-finder.component.scss']
})
export class WallpaperFinderComponent {
  storeId: string;
  ceilingHeight = 2400;
  wallWidth = 3000;
  tags = [];
  wallpapers: any[];
  tagCategories: { [name: string]: string } = {};
  selectedTagCategories: any = {};
  loading = true;
  page = 0;

  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, private route: ActivatedRoute, private scroll: Scroll, private sanitizer: DomSanitizer) {

  }

  async ngOnInit() {
    this.globals.storeId = this.storeId = this.elementRef.nativeElement.getAttribute('store-id');
    let tagCategories = await this.api.store().get<any[]>('/wallpaper/tag-categories');
    for (let category of tagCategories) {
      this.tagCategories[category.name] = category.tags;
    }
    await this.findWallpapers();
    this.loading = false;
  }

  async findWallpapers(page: number = 0) {
    this.page = page;
    this.loading = true;
    let tagGroups: any = [];
    for (let category in this.selectedTagCategories) {
      if (this.selectedTagCategories[category]) {
        tagGroups.push(this.tagCategories[category].split(','));
      }
    }

    let data = await this.api.store().post<any[]>('/wallpaper/find', {
      wallWidth: this.wallWidth,
      ceilingHeight: this.ceilingHeight,
      tagGroups: tagGroups,
      page: this.page
    });

    if (this.page == 0)
      this.wallpapers = [];
    for (let wallpaper of data) {
      this.wallpapers.push({
        id: wallpaper.id,
        title: wallpaper.title,
        handle: wallpaper.handle,
        url: `/products/${wallpaper.handle}`,
        price: parseFloat(wallpaper.variants[0].price),
        imageUrl: this.sanitizer.bypassSecurityTrustResourceUrl(wallpaper.images[0].src),
        mockupImageUrl: wallpaper.images[1] ? this.sanitizer.bypassSecurityTrustResourceUrl(wallpaper.images[1].src) : null
      });
    }

    this.loading = false;

    console.log(data);
  }

  async showMore() {
    await this.findWallpapers(this.page + 1);
  }

  async showPreview(product) {
    (window as any).showImagePreview(product);
  }



  async configureWallpaper(product: any) {
    (window as any).currentProduct = product;
    (window as any).triggerWallpaperConfigurator({ wallWidth: this.wallWidth, ceilingHeight: this.ceilingHeight });
  }
}
