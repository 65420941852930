<div class="align-center">
  <img class="ai-brain" src="{{globals.baseUrl}}/public-app/assets/ai-brain.png" />
  <br/>
  <h2>Super!</h2>
  <br/>
  <h3>Du har nu {{globals.customerAccount.credits}} krediter.</h3>
  <br/>
  <fancy-button (click)="continue()">Skapa din poster</fancy-button>
  <br/>
  <br/>
</div>
