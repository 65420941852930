import { Component, ElementRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

@Component({
  selector: 'credit-info',
  templateUrl: './credit-info.component.html',
  styleUrls: ['./credit-info.component.scss']
})
export class CreditInfoComponent {

  selectedCredits = 0;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private bottomSheetRef: MatBottomSheetRef<CreditInfoComponent>) {

  }

  async ngOnInit() {

  }

  async close() {
    this.bottomSheetRef.dismiss();
  }



}
