<div class="ace">
  <div class="row justify-content-center">
    <div class="col-6 align-center">
      <label>Takhöjd (mm)</label>
      <br />
      <input class="align-left" type="number" [(ngModel)]="ceilingHeight" />
    </div>

    <div class="col-6 align-center">
      <label>Väggbredd (mm)</label>
      <br />
      <input class="align-left" type="number" [(ngModel)]="wallWidth" />
    </div>
    <button class="button" *ngIf="isReady()" (click)="triggerWallpaperConfigurator()">Anpassa & köp</button>
  </div>
