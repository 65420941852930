import { Component, ElementRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { CreditInfoComponent } from '../bottom-sheets/credit-info/credit-info.component';
import { PaymentFinishedComponent } from '../bottom-sheets/payment-finished/payment-finished.component';

@Component({
  selector: 'add-credits',
  templateUrl: './add-credits.component.html',
  styleUrls: ['./add-credits.component.scss']
})
export class AddCreditsComponent {

  selectedCredits = 0;
  paymentFinished = false;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private bottomSheet: MatBottomSheet) {

  }

  async ngOnInit() {

  }

  async isPaid() {
    console.log('isPaid');
    this.globals.customerAccount.credits += this.selectedCredits;

    let sheet = this.bottomSheet.open(PaymentFinishedComponent, {
      data: {}
    });
  }

  async selectCredits(credits) {
    this.selectedCredits = credits;
  }

  openCreditInfo() {
    let sheet = this.bottomSheet.open(CreditInfoComponent, {
      data: {}
    });
  }


}
