<div class="ace" [ngClass]="{'overlayed': showConfigurator || imagePreviewUrl}">
  <div class="overlay" *ngIf="showConfigurator" (click)="cancel()"></div>
  <div class="configurator" #configuratorElement [ngClass]="{'has-error': errorMessage}" *ngIf="showConfigurator">
    <div class="top-panel">
      <div class="row">
        <div class="col">
          <h4 class="title">{{product.title}}</h4>
        </div>
        <div class="col align-right">
          <span class="material-symbols-outlined clickable" (click)="cancel()">close</span>
        </div>
      </div>
    </div>
    <div class="center-panel">
      <div class="center-overlay"></div>
      <div class="drag-area" #dragAreaElement>
        <img class="wallpaper-image" #wallpaperImageElement [src]="previewImageUrl" (load)="onImageLoaded()" />
        <div class="wallpaper-overlay">
          <div class="outside"></div>
          <div class="size-border" #sizeBorderElement [ngStyle]="{'aspect-ratio': configurationData.wallAspectRatio}">
            <ng-container *ngIf="showBreadths">
              <div class="breadth-line" *ngFor="let line of breadthLines; let i = index"
                [style.left]="line.xOffset + 'px'">
                <div class="breadth-label" [style.left]="(line.width/2) + 'px'">Våd {{i+1}}</div>
              </div>
            </ng-container>

          </div>
          <div class="outside"></div>
        </div>
      </div>
      <div class="center-overlay">
        <h3 *ngIf="errorMessage" class="align-center error-message">{{errorMessage}}</h3>
      </div>
    </div>

    <div class="bottom-panel">
      <div class="row">
        <div class="col-6 align-left">
          <div class="row">
            <div class="col-auto">
              <label>Takhöjd (mm)</label>
              <input class="align-left" type="number" [(ngModel)]="configurationData.ceilingHeight"
                (keyup)="updateBreadths()" />
            </div>
            <div class="col-auto">
              <label>Väggbredd (mm)</label>
              <input class="align-left" type="number" [(ngModel)]="configurationData.wallWidth"
                (keyup)="updateBreadths()" />
            </div>
            <div class="col-12">
              <mat-checkbox [(ngModel)]="showBreadths">
                Visa våder
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="col-6 align-right">
          <h3>Area: {{getSquareMeters()}}m<sup>2</sup></h3>
          <h3>Pris: {{getPrice()}}kr</h3>
          <button class="" [disabled]="errorMessage" (click)="addToCart()">Till varukorgen &gt;&gt;</button>
        </div>
      </div>

    </div>
  </div>

  <div class="image-preview"  *ngIf="imagePreviewUrl" (click)="hideImagePreview()">
    <img class="clickable" [src]="imagePreviewUrl" />
  </div>

</div>
