import { Component, ElementRef, Input } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'latest-generated-images',
  templateUrl: './latest-generated-images.component.html',
  styleUrls: ['./latest-generated-images.component.scss']
})
export class LatestGeneratedImagesComponent {

  imageUrls: SafeUrl[] = [];
  images: any[];


  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private sanitizer: DomSanitizer) {

  }

  async ngOnInit() {
    this.images = await this.api.store().get<any>(`/latest-generated-images`);
    for (let image of this.images) {
      this.imageUrls.push(this.sanitizer.bypassSecurityTrustUrl(image.url));
    }

  }

}
