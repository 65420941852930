import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email?: string;
  waitingForCode?: boolean = false;
  token?: string;
  code?: string;
  loginFailed?: boolean = false;

  @ViewChild('codeInput') codeInput?: ElementRef;
  constructor(private globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, private route: ActivatedRoute) {

  }


  async sendCode() {
    this.waitingForCode = true;
    this.loginFailed = false;
    let result = await this.api.store().post<any>(`/account/login`, { email: this.email });
    this.token = result.token;

    setTimeout(() => {
      this.codeInput?.nativeElement.focus();
    }, 100);
  }


  async verifyCode() {

    let result = await this.api.store().post<any>(`/account/verify-login`, { token: this.token, code: this.code });

    if (result.succeeded) {
      this.globals.customerAccount = result.customerAccount;
      this.waitingForCode = false;
      this.router.navigateByUrl('/generate-poster');
    }
    else {
      this.loginFailed = true;
    }
  }

  async reset() {
    this.waitingForCode = false;
    this.loginFailed = false;
    this.token = undefined;
    this.code = undefined;
  }

}
