<div>
  <br />
  <div class="row">
    <div class="col align-center">
      <img [src]="imageUrl" />
      <br />
      <br />
      <h3>Är du nöjd med bilden?</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 align-center">
      <fancy-button (click)="selectImage()">Välj bild</fancy-button>
    </div>
    <div class="col-12 align-center">
      <h3>eller</h3>
    </div>
    <div class="col-12 align-center">
      <fancy-button (click)="createVariation()">Generera varianter av denna bild</fancy-button>
    </div>
  </div>

  <br />
  <br/>
</div>
