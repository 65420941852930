<div class="ace wallpaper-finder">
  <div class="row justify-content-center">
    <div class="col-auto align-center">
      <label>Takhöjd (mm)</label>
      <br />
      <input class="align-left" type="number" [(ngModel)]="ceilingHeight" />
    </div>

    <div class="col-auto align-center">
      <label>Väggbredd (mm)</label>
      <br />
      <input class="align-left" type="number" [(ngModel)]="wallWidth" />
    </div>
    <div class="col align-center">
      <div>
        <label>Motivet skall innehålla:</label>
      </div>
      <div class="tag-category" *ngFor="let category of tagCategories | keyvalue"
        [ngClass]="{'selected': selectedTagCategories[category.key]}"
        (click)="selectedTagCategories[category.key] = !selectedTagCategories[category.key]; findWallpapers()">
        {{category.key}}</div>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <br />
      <button class="search-button fullwidth" (click)="findWallpapers()" [disabled]="loading">Visa</button>
    </div>
  </div>

  <div class="row" *ngIf="!loading">
    <div class="col-sm-4 wallpaper-product" *ngFor="let product of wallpapers">
      <div class="inner">
        <a [href]="product.url" target="_blank">
        <img class="clickable" (click)="showPreview(product)" [src]="product.imageUrl" />
        <div class="wallpaper-title">{{product.title}}</div>
        <div class="price">{{product.price}} SEK/m2</div>
        </a>
        <div class="row">
        <!--  <div class="col">
            <a [href]="product.url" target="_blank">
              <button class="fullwidth">Visa</button>
            </a>
          </div> -->
          <div class="col">
            <button class="fullwidth" (click)="configureWallpaper(product)">Anpassa & köp</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="wallpapers && !wallpapers.length">
      Vi kunde tyvärr inte hitta några produkter.
    </div>
    <div class="col-12" *ngIf="wallpapers && wallpapers.length">
      <br />
      <button class="search-button fullwidth" (click)="showMore()">Visa fler</button>
    </div>
  </div>

  <div *ngIf="loading" class="align-center">
    <span class="material-symbols-outlined loading-icon">progress_activity</span>
  </div>


</div>
