export const BREADTH_WIDTH = 645;
export const WALLPAPER_BREADTH_BLEED_HORIZONTAL_MM = 2;
export const WALLPAPER_BREADTH_BLEED_VERTICAL_MM = 2;

export interface IWallpaperConfigurationData {
    ceilingHeight?: number,
    wallWidth?: number,
    numberOfBreadths?: number,
    breadthAspectRatio?: number,
    breadthAspectRatioWithBleed?: number,
    wallAspectRatio?: number,
    offsetPercent?: {
        x: number,
        y: number
    },
    printFiles?: {
        fileName: string,
        fullName: string,
        contentType: string,
        url: string
    }[],
    previewFile?:{
        fileName: string,
        fullName: string,
        contentType: string,
        url: string
    }
}