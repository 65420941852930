<div>

  <div class="drag-area" #dragAreaElement>
    <img class="poster-image" #posterImageElement
      [src]="posterGenerationState.state.generatedImages[posterGenerationState.state.selectedImageIndex].safeUrl" />
    <div class="poster-overlay">
      <div class="outside"></div>
      <div class="size-border" #sizeBorderElement
        [ngStyle]="{'aspect-ratio': availableSizes[posterGenerationState.state.selectedSize].width + ' / ' + availableSizes[posterGenerationState.state.selectedSize].height}">
      </div>
      <div class="outside"></div>
    </div>
  </div>
  <br />
  <div class="align-center">
    Vattenstämpeln är till för att förhindra missbruk. Den kommer inte att synas på din poster.
  </div>
  <br />
  <div class="row" *ngIf="!addingToCart">
    <div class="col-lg-6 align-center">
      <br />
      <fancy-button (click)="back()">Välj en annan storlek</fancy-button>
    </div>
    <div class="col-lg-6 align-center">
      <br />
      <!-- <button class="styled" (click)="saveAlignment()">Fortsätt</button> -->
      <fancy-button (click)="addToCart()">Lägg i varukorgen</fancy-button>
    </div>
  </div>
  <div class="row" *ngIf="addingToCart">
    <div class="col align-center">
      <br />
      <span class="loader"></span>
    </div>
  </div>
</div>
