<div>
  <div *ngIf="batches">
    <h2 class="align-center">Dina senast genererade bilder</h2>
    <div class="batch" *ngFor="let batch of batches">
      <div class="row">
        <div class="col">
          <h4>"{{batch.prompt}}"</h4>
        </div>
        <div class="col-auto align-right">
          <h4>{{batch.created | date:'yyyy-MM-dd HH:mm'}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngFor="let image of batch.images; let i = index">
          <img [src]="image" />
        </div>
      </div>
      <div class="row">
        <div class="col align-center">
          <fancy-button (click)="selectBatch(batch)">Fortsätt med dessa bilder</fancy-button>
        </div>
      </div>
    </div>
  </div>

</div>
