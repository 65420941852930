<div class="ace bildbyran-checkout">
  <p><strong style="background-color: transparent;">Köpvillkor</strong></p>
  <p><br></p>
  <p><span style="background-color: transparent;">Vi på Min Poster Sverige AB (ACE Print Partner) är stolta över att
      erbjuda dig professionellt tryckta posters av bilder från Bildbyrån. Varje beställning trycks efter dina specifika
      önskemål, vilket gör varje produkt unik. Du har också möjlighet att justera måtten på dina posters så att de
      passar just dina behov.</span></p>
  <p><br></p>
  <p><strong style="background-color: transparent;">1. Allmänt</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Företagsinformation: Min Poster Sverige AB, org. nr
        559352-4746.</span></li>
    <li><span style="background-color: transparent;">Kontaktinformation: info&#64;aceprintpartner.com</span></li>
  </ul>
  <p><strong style="background-color: transparent;">2. Beställning</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Alla beställningar trycks efter dina specifikationer och är därmed
        skräddarsydda produkter.</span></li>
  </ul>
  <p><strong style="background-color: transparent;">3. Leverans</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Leveranstid: 2-4 arbetsdagar</span></li>
  </ul>
  <p><strong style="background-color: transparent;">4. Priser och Betalning</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Alla priser är inklusive moms.</span></li>
    <li><span style="background-color: transparent;">Betalningsmetoder: Vi accepterar betalningar via Klarna.</span>
    </li>
  </ul>
  <p><strong style="background-color: transparent;">5. Ångerrätt</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Eftersom alla våra produkter är skräddarsydda efter dina specifika
        önskemål, erbjuder vi ingen ångerrätt. När en beställning har lagts och produktionen har påbörjats, kan köpet
        inte ångras.</span></li>
  </ul>
  <p><strong style="background-color: transparent;">6. Retur och Reklamation</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Vid fel eller defekter på produkten, vänligen kontakta vår
        kundtjänst omedelbart.</span></li>
    <li><span style="background-color: transparent;">Vid retur eller reklamation, vänligen kontakta oss på </span><a
        href="mailto:info@aceprintpartner.com" target="_blank"
        style="background-color: transparent; color: rgb(17, 85, 204);">info&#64;aceprintpartner.com</a><span
        style="background-color: transparent;">&nbsp;</span></li>
    <li><span style="background-color: transparent;">Vid godkänd reklamation står vi för returfrakten.</span></li>
  </ul>
  <p><strong style="background-color: transparent;">7. Ej Upphämtade Paket</strong></p>
  <ul>
    <li><span style="background-color: transparent;">För ej upphämtade paket debiteras en avgift på 200 SEK.</span></li>
  </ul>
  <p><strong style="background-color: transparent;">8. Personuppgifter</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Vi behandlar dina personuppgifter i enlighet med GDPR. För mer
        information, se vår integritetspolicy.</span></li>
  </ul>
  <p><strong style="background-color: transparent;">9. Tvist</strong></p>
  <ul>
    <li><span style="background-color: transparent;">Eventuella tvister ska lösas i enlighet med svensk lag och i svensk
        domstol.</span></li>
  </ul>
  <h3><strong style="background-color: transparent;">Information om Ångerrätt</strong></h3>
  <p><span style="background-color: transparent;">Eftersom alla våra posters trycks efter beställning och anpassas efter
      dina specifika önskemål, erbjuder vi ingen ångerrätt. Detta innebär att när du har lagt en beställning och
      produktionen har påbörjats, kan köpet inte ångras.</span></p>
  <p><span style="background-color: transparent;">För frågor om detta, vänligen kontakta vår kundtjänst på
      info&#64;aceprintpartner.com.</span></p>
  <h3><strong style="background-color: transparent;">Retur och Reklamation</strong></h3>
  <p><span style="background-color: transparent;">Om du mottar en defekt eller felaktig produkt, vänligen kontakta oss
      omedelbart på </span><a href="mailto:info@aceprintpartner.com" target="_blank"
      style="background-color: transparent; color: rgb(17, 85, 204);">info&#64;aceprintpartner.com</a><span
      style="background-color: transparent;">. Vi kommer att ge dig instruktioner om hur du returnerar produkten. Vid
      godkänd reklamation står vi för returfrakten.&nbsp;</span></p>
  <h3><strong style="background-color: transparent;">Ej Upphämtade Paket</strong></h3>
  <p><span style="background-color: transparent;">För paket som inte hämtas ut från ombud debiteras en avgift på 200 SEK
      för att täcka våra hanteringskostnader.</span></p>
  <p><br></p>
</div>
