<div>
  <h2 class="align-center">Välj storlek</h2>
  <div class="available-sizes" [ngClass]="{'size-selected': selectedSize}">
    <div class="size-wrapper" [ngClass]="{'selected': size.size == selectedSize, 'landscape': size.width > size.height}"
      (click)="selectSize(size.size)" *ngFor="let size of availableSizesToDisplay"
      [ngStyle]="{'aspect-ratio': size.width + ' / ' + size.height, 'transform': 'scale(' + sizeScales[size.size] + ')'}">
      <div class="size-name">{{size.size}}cm
        <div class="price" *ngIf="!hidePrices || size.size == selectedSize">
          {{getSizePrice(size.size)}}
        </div>
      </div>
    </div>
  </div>
  <div #availableFramesAnchor></div>
  <br />
  <br />


  <div class="available-frames" *ngIf="selectedSize && frameExists(selectedSize)">
    <br />
    <h2 class="align-center">Vill du ha en ram till postern?</h2>
    <div class="row">
      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('BLACK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-black.png" />
          <h2>Svart</h2>
          <h3>{{getFramePrice('BLACK')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('WHITE')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-white.png" />
          <h2>Vit</h2>
          <h3>{{getFramePrice('WHITE')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('OAK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-oak.png" />
          <h2>Ek</h2>
          <h3>{{getFramePrice('OAK')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_GOLD')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-gold.png" />
          <h2>Guld metall</h2>
          <h3>{{getFramePrice('M_GOLD')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_SILVER')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-silver.png" />
          <h2>Silver metall</h2>
          <h3>{{getFramePrice('M_SILVER')}}</h3>
        </div>
      </div>

      <div class="col">
        <div class="frame clickable align-center" (click)="selectFrame('M_BLACK')">
          <img src="{{globals.baseUrl}}/public-app/assets/frame-metal-black.png" />
          <h2>Svart metall</h2>
          <h3>{{getFramePrice('M_BLACK')}}</h3>
        </div>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col">
        <div class="padded">
          <button class="styled fullwidth" (click)="noFrame()">Nej tack</button>
        </div>
      </div>

      <br />
      <br />
    </div>
  </div>
</div>
