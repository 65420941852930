import { Component, ElementRef, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';

@Component({
  selector: 'selected-image',
  templateUrl: './selected-image.component.html',
  styleUrls: ['./selected-image.component.scss']
})
export class SelectedImageComponent {
  imageUrl: SafeUrl;
  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, private router: Router, public posterGenerationState: PosterGenerationState, private bottomSheetRef: MatBottomSheetRef<SelectedImageComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: { imageUrl: string, selectImage: any, createVariation: any }, private sanitizer: DomSanitizer) {
    this.imageUrl = sanitizer.bypassSecurityTrustUrl(data.imageUrl);
  }

  async ngOnInit() {

  }

  async close() {
    this.bottomSheetRef.dismiss();
  }


  async selectImage() {
    this.data.selectImage();
    this.close();
  }

  async createVariation() {
    this.data.createVariation();
    this.close();
  }
}
