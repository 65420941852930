import { Component, ElementRef, ViewChild } from '@angular/core';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterGenerationState } from 'src/app/services/poster-generation-state';
import { Scroll } from 'src/app/services/scroll';
import { numberFormat } from '../../../../../../../common/common-helpers/utility';
import { canvasSizesByName, posterSizesByName, squareCanvasSizes, squarePosterSizes, verticalCanvasSizes, verticalPosterSizes } from '../../../../../../../common/common-types/poster-size';
import { PosterType } from '../../../../../../../common/common-types/poster-type';
import { PosterFrameMaterial } from '../../../../../../../common/common-types/poster-frame';
@Component({
  selector: 'ai-poster-size-selector',
  templateUrl: './poster-size-selector.component.html',
  styleUrls: ['./poster-size-selector.component.scss']
})
export class AiPosterSizeSelectorComponent {
  availableSizes = posterSizesByName;
  selectedSize?: string;
  sizeScales = {
    '21x30': .21,
    '20x30': .20,
    '30x40': .3,
    '50x50': .5,
    '50x70': .5,
    '70x100': 1,
  }

  @ViewChild('availableFramesAnchor')
  availableFramesAnchor?: ElementRef<HTMLElement>;

  @ViewChild('sizeAnchor')
  sizeAnchor?: ElementRef<HTMLElement>;

  constructor(public globals: Globals, private elementRef: ElementRef, private api: Api, public posterGenerationState: PosterGenerationState, private scroll: Scroll) {

  }

  async ngOnInit() {
    setTimeout(() => {
      this.scroll.scrollToTop();
    }, 100)
  }

  getAvailableSizes() {
    let result: { [key: string]: any } = {};
    if (this.posterGenerationState.state.type == 'POSTER') {
      for (let size of verticalPosterSizes.concat(squarePosterSizes))
        result[size] = posterSizesByName[size];
    }
    else if (this.posterGenerationState.state.type == 'CANVAS') {
      for (let size of verticalCanvasSizes.concat(squareCanvasSizes))
        result[size] = canvasSizesByName[size];
    }
    return result;
  }

  selectSize(size: string) {
    if (this.posterGenerationState.state.type == 'POSTER' && this.frameExists(size)) {
      this.selectedSize = size;
      setTimeout(() => {
        this.availableFramesAnchor.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
    else {
      this.posterGenerationState.state.selectedSize = size;
      this.posterGenerationState.save();
    }
  }

  selectType(type: PosterType) {
    if (type != 'POSTER') {
      delete this.selectedSize;
      delete this.posterGenerationState.state.frame;
    }
    this.posterGenerationState.state.type = type;
    this.posterGenerationState.save();
    setTimeout(() => {
      this.sizeAnchor.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  getPosterTypePriceRange(type: PosterType) {
    let data = (window as any).productInfo;
    let max = 0;
    let min = 99999;

    let typeData;
    switch (type) {
      case 'POSTER':
        typeData = data.poster;
        break;
      case 'CANVAS':
        typeData = data.canvas;
        break;
    }
    for (let size of Object.keys(typeData)) {
      max = Math.max(max, typeData[size].price);
      min = Math.min(min, typeData[size].price);
    }

    return `${numberFormat(min)} kr - ${numberFormat(max)} kr`;
  }
  async back() {
    delete this.posterGenerationState.state.selectedImageIndex;
  }

  getPosterTypeSizePrice(type: PosterType, size: string) {
    let data = (window as any).productInfo;
    let typeData;
    switch (type) {
      case 'POSTER':
        typeData = data.poster;
        break;
      case 'CANVAS':
        typeData = data.canvas;
        break;
    }
    return `${numberFormat(typeData[size].price)} kr`;
  }

  getFramePrice(color: PosterFrameMaterial) {
    let data = (window as any).productInfo;
    let price = data.frame[this.selectedSize][color].price;
    return `${numberFormat(price)} kr`;
  }

  frameExists(size: string) {
    let data = (window as any).productInfo;
    return data['frame'][size];
  }

  selectFrame(color: PosterFrameMaterial) {
    this.posterGenerationState.state.frame = color;
    this.posterGenerationState.state.selectedSize = this.selectedSize;
    this.posterGenerationState.save();
  }

  async continue() {

  }
}


